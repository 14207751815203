import { AdminRoutesEcpf, BackofficeRoutesEcpf, ClientRoutesEcpf, CorbanRoutesEcpf, MasterRoutesEcpf, OperatorRoutesEcpf } from 'ecp/models/EcpfSidebar'
import {
  AdiministratorRoutesEcp,
  AgxRoutesEcp,
  AuditorRoutesEcp,
  BackofficeRoutesEcp,
  ClientRoutesEcp,
  CommissionRoutesEcp,
  ConsultantRoutesEcp,
  MasterRoutesEcp,
  PricingRoutesEcp,
  PromoterRoutesEcp,
  ProviderRoutesEcp,
  RhRoutesEcp,
  SellerRoutesEcp,
  OperatorRoutesEcp
} from 'ecp/models/EcpSidebar'
import { _userLevel } from 'ecp/models/UsersModel'
import { IUserPermission } from 'egi/repositories/PermissionsRepository'
import { IAuth } from 'egi/types'

export const getEcpfRoutes = (level: _userLevel, auth: IAuth, userPermissions: IUserPermission[]) => {
  switch (level) {
    case 'master':
      return new MasterRoutesEcpf(auth, userPermissions)
    case 'operatorFuncao':
      return new OperatorRoutesEcpf(userPermissions)
    case 'corbanFuncao':
      return new CorbanRoutesEcpf(userPermissions)
    case 'client':
      return new ClientRoutesEcpf(userPermissions)
    case 'administrator':
      return new AdminRoutesEcpf(auth, userPermissions)
    case 'backoffice':
      return new BackofficeRoutesEcpf(auth, userPermissions)
    default:
      return undefined
  }
}

export const getEcpRoutes = (level: _userLevel, auth: IAuth) => {
  switch (level) {
    case 'agx':
      return new AgxRoutesEcp()
    case 'master':
      return new MasterRoutesEcp(auth)
    case 'commercial':
      return new CommissionRoutesEcp()
    case 'administrator':
      return new AdiministratorRoutesEcp(auth)
    case 'backoffice':
      return new BackofficeRoutesEcp(auth)
    case 'promoter':
      return new PromoterRoutesEcp(auth)
    case 'commission':
      return new CommissionRoutesEcp()
    case 'pricing':
      return new PricingRoutesEcp()
    case 'consultant':
      return new ConsultantRoutesEcp()
    case 'provider':
      return new ProviderRoutesEcp()
    case 'seller':
      return new SellerRoutesEcp()
    case 'auditor':
      return new AuditorRoutesEcp()
    case 'client':
      return new ClientRoutesEcp(auth)
    case 'RH':
      return new RhRoutesEcp()
    case 'operator':
      return new OperatorRoutesEcp()
    default:
      return undefined
  }
}
