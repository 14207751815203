import { AreaChartOutlined, BankOutlined, FileSyncOutlined, InteractionOutlined, MessageOutlined, RetweetOutlined, SettingOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { IRoute } from 'components/Sidebar/types'
import { ClientDetailsECPF, ClientFormECPF, ClientInitialECPF } from 'ecpf/app/ClientECPF/ClientLoaderECPF'
import { ConfigurationInsuranceECPF, ConfigurationMainECPF, ConfigurationMaximumRequestedValueECPF, ProposalConfigurationECPF } from 'ecpf/app/ConfigurationECPF/ConfigurationECPFLoader'
import EmployerECPFList from 'ecpf/app/EmployerECPF/views/EmployerECPFList/EmployerECPFList'
import { FindUserECPF, FindUserRefinancingECPF } from 'ecpf/app/FindUserECPF/FindUserECPFLoader'
import { MainOfficeListECPF } from 'ecpf/app/MainOfficeECPF/MainOfficeECPFLoader'
import { OrganizationECPFList } from 'ecpf/app/OrganizationECPF/OrganizationECPFLoader'
import { PortabilityECPF } from 'ecpf/app/PortabilityECPF/PortabilityECPFLoader'
import { PromotorECPFList } from 'ecpf/app/PromotorECPF/PromotorECPFLoader'
import SelectContractsECPF from 'ecpf/app/ProposalECPF/components/SelectContractsECPF/SelectContractsECPF'
import { ProposalType } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { ProposalDetailsECPF, ProposalFlowECPF, ProposalsListECPF } from 'ecpf/app/ProposalECPF/ProposalECPFLoader'
import { RegionalListECPF } from 'ecpf/app/RegionalECPF/RegionalECPFLoader'
import { TypistListECPF } from 'ecpf/app/TypistECPF/TypistECPFLoader'
import ProposalFuncaoModel, { ProposalCustomerFlowClientType } from 'ecpf/models/ProposalFuncaoModel'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ECPF } from './constants'
import { userDetails } from 'routes/routes'
import GenericChat from 'egi/app/GenericChat/GenericChat'

const ecpfFindUserNewContract: IRoute = {
  path: ECPF.FIND_USER.path,
  name: ECPF.FIND_USER.name,
  icon: AreaChartOutlined,
  component: () => {
    const history = useHistory()

    return (
      <FindUserECPF
        onRedirect={(flowType: ProposalCustomerFlowClientType) => {
          history.push(ProposalFuncaoModel.generateRedirectProposalFlow(ProposalType.new, flowType))
        }}
      />
    )
  },
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfFindUserPortability: IRoute = {
  path: ECPF.FIND_USER_PORTABILITY.path,
  name: ECPF.FIND_USER_PORTABILITY.name,
  icon: AreaChartOutlined,
  component: () => {
    const history = useHistory()

    return (
      <FindUserECPF
        onRedirect={(flowType: ProposalCustomerFlowClientType) => {
          history.push(ProposalFuncaoModel.generateRedirectProposalFlow(ProposalType.portability, flowType))
        }}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpfFindUserRefinancing: IRoute = {
  path: ECPF.FIND_USER_REFINANCING.path,
  name: ECPF.FIND_USER_REFINANCING.name,
  icon: InteractionOutlined,
  component: () => {
    const history = useHistory()

    return (
      <FindUserRefinancingECPF
        onRedirect={() => {
          history.push({
            pathname: '/auth' + ECPF.SELECT_CONTRACTS_REFINANCING.path
          })
        }}
      />
    )
  },
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfSelectContractsRefinancing: IRoute = {
  path: ECPF.SELECT_CONTRACTS_REFINANCING.path,
  name: ECPF.SELECT_CONTRACTS_REFINANCING.name,
  icon: InteractionOutlined,
  component: () => {
    const history = useHistory()
    return (
      <SelectContractsECPF
        onRedirect={(flowType: ProposalCustomerFlowClientType) => {
          history.push(ProposalFuncaoModel.generateRedirectProposalFlow(ProposalType.refinancing, flowType))
        }}
        description='Refinancie o seus contratos em um novo e receba a diferença adicional na sua conta'
        initialPath={'/auth' + ECPF.FIND_USER_REFINANCING.path}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfFindUserRenegotiation: IRoute = {
  path: ECPF.FIND_USER_RENEGOTIATION.path,
  name: ECPF.FIND_USER_RENEGOTIATION.name,
  icon: FileSyncOutlined,
  component: () => {
    const history = useHistory()

    return (
      <FindUserRefinancingECPF
        onRedirect={() => {
          history.push({
            pathname: '/auth' + ECPF.SELECT_CONTRACTS_RENEGOTIATION.path
          })
        }}
      />
    )
  },
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfSelectContractsRenegotiation: IRoute = {
  path: ECPF.SELECT_CONTRACTS_RENEGOTIATION.path,
  name: ECPF.SELECT_CONTRACTS_RENEGOTIATION.name,
  icon: FileSyncOutlined,
  component: () => {
    const history = useHistory()
    return (
      <SelectContractsECPF
        onRedirect={(flowType: ProposalCustomerFlowClientType) => {
          history.push(ProposalFuncaoModel.generateRedirectProposalFlow(ProposalType.renegotiation, flowType))
        }}
        description='Renegocie os seus contratos em um novo.'
        initialPath={'/auth' + ECPF.FIND_USER_RENEGOTIATION.path}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfOperatorList: IRoute = {
  path: ECPF.OPERATOR_LIST.path,
  name: ECPF.OPERATOR_LIST.name,
  icon: UserOutlined,
  component: TypistListECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.OPERATOR_LIST.path,
      search: ECPF.OPERATOR_LIST.search
    }
  }
}

const ecpfProposalFlow: IRoute = {
  path: ECPF.PROPOSAL_FLOW.path,
  name: ECPF.PROPOSAL_FLOW.name,
  icon: AreaChartOutlined,
  component: ProposalFlowECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfMainOfficeList: IRoute = {
  path: ECPF.MAIN_OFFICE_LIST.path,
  name: ECPF.MAIN_OFFICE_LIST.name,
  icon: BankOutlined,
  component: MainOfficeListECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.MAIN_OFFICE_LIST.path,
      search: ECPF.MAIN_OFFICE_LIST.search
    }
  }
}

const ecpfProposalsList: IRoute = {
  path: ECPF.PROPOSAL_LIST.path,
  name: ECPF.PROPOSAL_LIST.name,
  icon: SolutionOutlined,
  component: ProposalsListECPF,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.PROPOSAL_LIST.path,
      search: ECPF.PROPOSAL_LIST.search
    }
  }
}

const ecpfProposalDetails: IRoute = {
  path: ECPF.PROPOSAL_DETAILS.path,
  name: ECPF.PROPOSAL_DETAILS.name,
  icon: SolutionOutlined,
  component: ProposalDetailsECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true
  }
}

const ecpfRegionalList: IRoute = {
  path: ECPF.REGIONAL_LIST.path,
  name: ECPF.REGIONAL_LIST.name,
  icon: SolutionOutlined,
  component: RegionalListECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.REGIONAL_LIST.path,
      search: ECPF.REGIONAL_LIST.search
    }
  }
}

const ecpfPromoterList: IRoute = {
  path: ECPF.PROMOTER_LIST.path,
  name: ECPF.PROMOTER_LIST.name,
  icon: UserOutlined,
  component: PromotorECPFList,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.PROMOTER_LIST.path,
      search: ECPF.PROMOTER_LIST.search
    }
  }
}

const ecpfEmployerList: IRoute = {
  path: ECPF.EMPLOYER_LIST.path,
  name: ECPF.EMPLOYER_LIST.name,
  icon: UserOutlined,
  component: EmployerECPFList,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      pathname: ECPF.EMPLOYER_LIST.path,
      search: ECPF.EMPLOYER_LIST.search
    }
  }
}

const ecpfOrganizationList: IRoute = {
  path: ECPF.ORGANIZATION_LIST.path,
  name: ECPF.ORGANIZATION_LIST.name,
  icon: UserOutlined,
  component: OrganizationECPFList,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true,
    location: {
      pathname: ECPF.ORGANIZATION_LIST.path,
      search: ECPF.ORGANIZATION_LIST.search
    }
  }
}

const ecpfClientDetails: IRoute = {
  path: ECPF.CLIENT_DETAILS.path,
  name: ECPF.CLIENT_DETAILS.name,
  icon: UserOutlined,
  component: ClientDetailsECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true
  }
}

const ecpfClientInitial: IRoute = {
  path: ECPF.CLIENT_INITIAL_PAGE.path,
  name: ECPF.CLIENT_INITIAL_PAGE.name,
  icon: UserOutlined,
  component: ClientInitialECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true,
    location: {
      pathname: ECPF.CLIENT_INITIAL_PAGE.path,
      search: ECPF.CLIENT_INITIAL_PAGE.search
    }
  }
}

const ecpfClientForm: IRoute = {
  path: ECPF.CLIENT_FORM.path,
  name: ECPF.CLIENT_FORM.name,
  icon: UserOutlined,
  component: ClientFormECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true
  }
}

const ecpfConfiguration: IRoute = {
  path: ECPF.CONFIGURATION_MAIN.path,
  name: ECPF.CONFIGURATION_MAIN.name,
  icon: SettingOutlined,
  component: ConfigurationMainECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfConfigurationInsurance: IRoute = {
  path: ECPF.CONFIGURATION_INSURANCE.path,
  name: ECPF.CONFIGURATION_INSURANCE.name,
  icon: SettingOutlined,
  component: ConfigurationInsuranceECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfConfigurationMaximumRequestedValue: IRoute = {
  path: ECPF.CONFIGURATION_MAXIMUM_REQUESTED_VALUE.path,
  name: ECPF.CONFIGURATION_MAXIMUM_REQUESTED_VALUE.name,
  icon: SettingOutlined,
  component: ConfigurationMaximumRequestedValueECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfConfigurationProposal: IRoute = {
  path: ECPF.CONFIGURATION_PROPOSAL.path,
  name: ECPF.CONFIGURATION_PROPOSAL.name,
  icon: SettingOutlined,
  component: ProposalConfigurationECPF,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpfPortability: IRoute = {
  path: ECPF.PORTABILITY_HOME.path,
  name: ECPF.PORTABILITY_HOME.name,
  icon: RetweetOutlined,
  component: PortabilityECPF,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfChat: IRoute = {
  path: ECPF.CHAT.path,
  name: ECPF.CHAT.name,
  icon: MessageOutlined,
  component: GenericChat,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpfRoutes = {
  ecpfFindUserNewContract,
  ecpfFindUserPortability,
  ecpfFindUserRefinancing,
  ecpfFindUserRenegotiation,

  // SELECT CONTRACTS
  ecpfSelectContractsRefinancing,
  ecpfSelectContractsRenegotiation,

  // PROPOSALS
  ecpfProposalFlow,
  ecpfProposalsList,
  ecpfProposalDetails,

  // PORTABILITY
  ecpfPortability,

  // ORIGENS
  ecpfMainOfficeList,
  ecpfRegionalList,
  ecpfOperatorList,
  ecpfPromoterList,
  ecpfEmployerList,
  ecpfOrganizationList,

  // CLIENTS
  ecpfClientDetails,
  ecpfClientForm,
  ecpfClientInitial,

  // CONFIGURATION
  ecpfConfiguration,
  ecpfConfigurationInsurance,
  ecpfConfigurationMaximumRequestedValue,
  ecpfConfigurationProposal,

  // CHAT,
  ecpfChat,

  userDetails

}

export type EcpfRoutes = typeof ecpfRoutes

export {
  ecpfRoutes
}
