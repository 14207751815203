import React, { useState } from 'react'
import useDownloadNotification from 'hooks/useDownloadNotification'
import { useAuth, useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { ISearch } from 'components/SeverTable/types'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { downloadCSVFile } from 'components/SeverTable/serverTableFunctions'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { makeMonthPeriod, timeAsDayjs } from 'utils/time'
import swal from 'utils/swal'
import ProposalEgiReportModal, { findProposalReport } from '../ProposalEgiReportModal/ProposalsReportModal'
import MoreOptionsTwo from 'components/MoreOptions/MoreOptionsTwo'
import ButtonModel from 'components/SeverTable/ButtonModel'
import { DownloadOutlined, ExportOutlined, LoadingOutlined, SettingOutlined } from '@ant-design/icons'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { proposalStatusesToArray } from 'egi/app/ProposalEgi/components/ProposalEgiReportModal/ProposalsReportModal'
import { SortConfiguration } from '../SortConfiguration/SortConfiguration'
import { Drawer, message } from 'antd'

function ActionsButton () {
  const { requestDownload, isDownloading } = useDownloadNotification('prognumDocs')
  const tables = useTables()
  const dispatch = useDispatch()
  const user = useAuth()

  const [loadingReports, setLoadingReports] = useState<boolean>(false)
  const [loadingReportsSignatures, setLoadingReportsSignatures] = useState<boolean>(false)
  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const [showSortConfiguration, setShowSortConfiguration] = useState<boolean>(false)

  const getReport = async (params: ISearch, type: string): Promise<void> => {
    const finalParams = { ...params, createdDateAfter: undefined, createdDateBefore: undefined } as ISearch

    if (type === 'download') setLoadingReports(true)
    if (type === 'downloadSignature') setLoadingReportsSignatures(true)

    try {
      const response = await ProposalEgiRepository.list({ params: finalParams })

      const CSV = response.data.data.csv

      if (CSV) {
        downloadCSVFile(CSV, `Proposta_EGI_${type}`)
      }
    } catch (err) {
      message.error(err.message)
    } finally {
      dispatch(tablesSetFiltersValues({ ...tables.filters, download: undefined, downloadSignatures: undefined }))
      setLoadingReports(false)
      setLoadingReportsSignatures(false)
    }
  }

  const handleShowSortConfiguration = () => {
    setShowSortConfiguration(prev => !prev)
  }

  async function downloadPrognumReport () {
    try {
      if (!tables.filters.createdDate) throw new Error('Selecione a data de criação')
      const filterDate = (tables.filters.createdDate).split('-')
      const { firstDay, lastDay } = makeMonthPeriod({ year: Number(filterDate[1]), month: Number(filterDate[0]) })
      const response = await ProposalEgiRepository.createPrognum({
        startDate: timeAsDayjs(firstDay).format('YYYY-MM-DD'),
        endDate: timeAsDayjs(lastDay).format('YYYY-MM-DD'),
        onlyApproved: false
      })

      const prognumId = response.data.data?.docIds?.[0]
      requestDownload(() => ProposalEgiRepository.getPrognum({ params: { prognumId } }))
    } catch (err) {
      swal.basic({
        title: 'Atenção',
        text: err.message,
        icon: 'warning'
      })
    }
  }

  return <>
    <ProposalEgiReportModal
      url='/proposals'
      hasProposalStatus
      visible={showReportModal}
      onVisibleChange={setShowReportModal}
    />

    <MoreOptionsTwo disabled={tables.ignoreAll}
      contents={
        <>
          <ButtonModel
            onClick={() => {
              const hasSlowStatusReport =
                !tables.filters.proposalStatus ||
                !tables.filters.proposalStatus.length ||
                !tables.filters.createdDate ||
                findProposalReport(proposalStatusesToArray(String(tables.filters.proposalStatus)))

              if (!hasSlowStatusReport) getReport({ ...tables.filters, download: true }, 'download')
              if (hasSlowStatusReport) setShowReportModal(true)
            }}
            text='Gerar relatório'
            key='download'
            icon={
              loadingReports ? <LoadingOutlined /> : <DownloadOutlined
                className="server-table-filters__icon-button-model"
              />
            }
          />

          {ProposalEgiModel.canChooseReportType(user.level, user.areaName) && (
            <ButtonModel
              onClick={() => {
                getReport({ ...tables.filters, downloadSignatures: true }, 'downloadSignature')
              }}
              text='Relatório de assinatura'
              icon={!loadingReportsSignatures ? <ExportOutlined className="server-table-filters__icon-button-model" /> : <LoadingOutlined />}
              key={'downloadSignatures'}
            />
          )}

          {ProposalEgiModel.canDownloadPrognumReport(user.level, user.areaName) && (
            <ButtonModel
              onClick={() => downloadPrognumReport()}
              text='Relatório Prognum'
              icon={
                isDownloading
                  ? <LoadingOutlined className="server-table-filters__icon-button-model" />
                  : <ExportOutlined className="server-table-filters__icon-button-model" />
              }
              key={'downloadPrognumReport'}
            />
          )}

          <ButtonModel
            onClick={handleShowSortConfiguration}
            text='Config. Ordenação'
            icon={<SettingOutlined className="server-table-filters__icon-button-model" />}
            key='configSort'
          />
        </>
      }
      buttonTitle="Ações"
      filtersClicked={true}
    />

    <Drawer
      visible={showSortConfiguration}
      onClose={handleShowSortConfiguration}
      title='Configuração de ordenação padrão'
      footer={null}
      width={500}
    >
      <SortConfiguration />
    </Drawer>
  </>
}

export { ActionsButton }
