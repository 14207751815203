import React, { Fragment, useEffect, useState } from 'react'
import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd'
import { Input, InputMoney } from 'components'
import { MinusCircleOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { useAuth, useClient, useProposal, useStep } from 'hooks'
import { proposals } from 'egi/consumers'
import format from 'utils/format'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import { IFormIncome, employmentType, IOtherIncome, IFormIncomeErrors } from './types'
import { IFormProps } from '../types'
import CurrencyInput from 'react-currency-input-field'
import { useDispatch } from 'react-redux'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import PlusButton from 'components/PlusButton/PlusButton'
import Datepicker from 'components/Datepicker/Datepicker'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function FormIncome ({ formRef, onType, onLoading, callbackProposal, readOnlyInput, openSimulationDrawer }: IFormProps) {
  const [errors, setErrors] = useState<IFormIncomeErrors>({})
  const [employmentType, setEmploymentType] = useState<employmentType>()
  const [person, setPerson] = useState<string>()
  const [composeIncome, setComposeIncome] = useState<boolean>(false)
  const [inputName, setInputName] = useState<string>('cnpjEmployer')

  const [income, setIncome] = useState<number>(0)
  const [finalIncome, setFinalIncome] = useState(0)

  const step = useStep()
  const user = useAuth()
  const client = useClient()
  const proposal = useProposal()
  const dispatch = useDispatch()

  function finalIncomeCalculate (value: string) {
    let tempIncome: number = Number((value || '0').replace(',', '.') || Number(value))
    let multipleIncomes = []

    if (formRef) {
      const hasIncomes = formRef.getFieldValue('otherIncomes')

      if (hasIncomes && hasIncomes.length) {
        multipleIncomes = hasIncomes

        if (multipleIncomes.length) {
          const removeComma = multipleIncomes.map((item: { value: string }) => {
            const convertValue = format.formatMoneySend(item?.value) || 0
            return convertValue
          })
          if (removeComma.length > 0) tempIncome += removeComma.reduce((acc: number, value: number) => Number(acc) + Number(value))
        }
      }
    }

    if (isNaN(tempIncome)) {
      setFinalIncome(0)
    } else {
      setFinalIncome(Number(tempIncome.toFixed(2)))
    }
  }

  const onSubmit = async (values: IFormIncome) => {
    const formData: any = {}

    if (user.level === 'promoter') {
      formData.clientId = String(client.id)
    }

    if (values.autonomoStartActivityDate) values.autonomoStartActivityDate = time(correctTimezone(String(values.autonomoStartActivityDate)))

    if (values.cpfEmployer) {
      values.cpfEmployer = format.onlyDigits(String(values.cpfEmployer))

      if (values.cpfEmployer.length >= 12) {
        values.cnpjEmployer = values.cpfEmployer
        delete values.cpfEmployer
      }
    }
    if (values.cnpjEmployer) {
      values.cnpjEmployer = format.onlyDigits(String(values.cnpjEmployer))

      if (values.cnpjEmployer.length <= 11) {
        values.cpfEmployer = values.cnpjEmployer
        delete values.cnpjEmployer
      }
    }

    if (employmentType === 'aposentado' && !values.privateSocialSecurity) values.privateSocialSecurity = false
    if (values.income) values.income = format.formatMoneySend(String(values.income))

    const otherIncomes = (values as IFormIncome).otherIncomes
    formData.otherIncomes = []

    if (otherIncomes) {
      delete values.otherIncomes
      otherIncomes.forEach((income: IOtherIncome) => {
        if (income.value) {
          const formattedIncome = format.formatMoneySend(String(income.value))
          formData.otherIncomes.push({
            value: formattedIncome,
            name: income.name,
            rented: income.rented
          })
        }
      })
    }

    formData.totalIncome = finalIncome

    try {
      if (!proposal.id) throw new Error('Não foi possível encontrar o identificador da proposta')

      const props = {
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId,
        form: { ...formData, ...values }
      }

      let response

      if (onType === 'send') {
        onLoading('sending')
        props.form = { ...props.form, finished: true }
        response = await proposals.send(props)
      } else if (onType === 'save') {
        onLoading('saving')
        props.form = { ...props.form, finished: false }
        response = await proposals.send(props)
      }

      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      setErrors({})
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
        swal.confirm({
          icon: 'warning',
          title: 'Atenção',
          html: err.message,
          confirmButtonText: 'Refazer simulação',
          showCancelButton: false,
          confirm: () => {
            if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
          }
        })
      }
      if (err.data?.invalid) {
        let errors: IFormIncomeErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      return false
    } finally {
      onLoading('')
    }
  }

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    async function onFill () {
      const formData = Object.assign({}, step.form)

      if (formData.cpfEmployer) setInputName('cpfEmployer')
      if (formData.cnpjEmployer) setInputName('cnpjEmployer')

      if (formData.income) formData.income = parseFloat(formData.income).toFixed(2)
      else formData.income = undefined

      if (formData.autonomoStartActivityDate) formData.autonomoStartActivityDate = timeAsDayjs(formData.autonomoStartActivityDate, { applyTimezone: false }).format('DD/MM/YYYY')
      else formData.autonomoStartActivityDate = undefined

      if (formData.employmentType) setEmploymentType(formData.employmentType)
      else setEmploymentType('')

      if (formData.composeIncome) setComposeIncome(formData.composeIncome)

      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      formRef.setFieldsValue(formData)

      if (formData.income) {
        setIncome(Number(formData.income))
        finalIncomeCalculate(formData.income || '0')
      }
    }

    const getPerson = () => {
      if (step) {
        if (step.name.includes('cônjuge')) {
          setPerson('cônjuge')
        } else if (step.name.includes('fiador')) {
          setPerson('fiador')
        } else { setPerson(undefined) }
      }
    }

    resetFields()
    getPerson()
    onFill()

    return () => {
      resetFields()
    }
  }, [step.form, formRef, step._id, step.situation])

  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        composeIncome: false,
        irFree: false,
        hasServiceAgreement: false
      }}
    >
      <Row>
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='employmentType'
            label={<label >Tipo profissional:</label>}
            help={errors.employmentType && (errors.employmentType)}
            validateStatus={errors.employmentType && ('error')}
          >
            <Select
              id="test-form-select-renda"
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
              onChange={(value: employmentType) => setEmploymentType(value)}
              placeholder={
                person
                  ? `Escolha o tipo profissional do ${person}`
                  : 'Escolha seu tipo profissional'
              }
            >
              <Select.Option id="test-form-select-renda-empregado" value="assalariado">Assalariado(a)</Select.Option>
              <Select.Option id="test-form-select-renda-funcionarioPublico" value="funcionarioPublico">Funcionário(a) público(a)</Select.Option>
              <Select.Option id="test-form-select-renda-aposentado" value="aposentado">Aposentado(a)</Select.Option>
              <Select.Option id="test-form-select-renda-liberal" value="liberal">Profissional liberal</Select.Option>
              <Select.Option id="test-form-select-renda-autonomo" value="autonomo">Autônomo(a)</Select.Option>
              <Select.Option id="test-form-select-renda-empresario" value="empresario">Empresario(a)</Select.Option>
              <Select.Option id="test-form-select-renda-rural" value="rural">Produtor(a) rural</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        {employmentType === 'empresario' ? (
          <></>
        ) : (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='occupation'
              label={<label >Profissão:</label>}
              help={errors.occupation && (errors.occupation)}
              validateStatus={errors.occupation && ('error')}
            >
              <Input
                readOnly={readOnlyInput}
                placeholder={person ? `Digite a profissão do ${person}` : 'Digite sua profissão'}
                error={errors.occupation}></Input>
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='income'
            label={<label>Rendimentos:</label>}
          >
            <InputMoney
              onChange={(value: number | React.ChangeEvent<HTMLInputElement>) => {
                setIncome(value as number)
                finalIncomeCalculate(String(value))
              }}
              readOnly={readOnlyInput}
              name='income'
              placeholder='Digite o valor dos rendimentos'
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='residenceType'
            label={<label >Tipo de residência:</label>}
            help={errors.residenceType && (errors.residenceType)}
            validateStatus={errors.residenceType && ('error')}
          >
            <Select
              id="test-form-select-residenceType"
              placeholder='Digite o tipo de residência'
              disabled={readOnlyInput}
            >
              <Select.Option id="test-form-select-residenceType-propria" value="owner">Própria</Select.Option>
              <Select.Option id="test-form-select-residenceType-alugada" value="rented">Alugada</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        {(employmentType === 'assalariado') && (
          <Fragment>
            <Col className="px-1" lg={8} sm={12} xs={24}>
              <Form.Item
                name='employer'
                label={<label>Empregador:</label>}
                help={errors.employer && (errors.employer)}
                validateStatus={errors.employer && ('error')}
              >
                <Input
                  name='employer'
                  readOnly={readOnlyInput}
                  placeholder={'Digite o nome do empregador'}
                />
              </Form.Item>
            </Col>

            <Col className="px-1" lg={8} sm={12} xs={24}>
              <Form.Item
                name={inputName}
                label={<label>CPF ou CNPJ do empregador:</label>}
                help={(errors.cnpjEmployer && (errors.cnpjEmployer)) || (errors.cpfEmployer && (errors.cpfEmployer))}
                validateStatus={(errors.cnpjEmployer || errors.cpfEmployer) && ('error')}
              >
                <Input
                  name={inputName}
                  readOnly={readOnlyInput}
                  placeholder='Digite o cpf ou o cnpj do empregador'
                  mask="cpfcnpj"
                />
              </Form.Item>
            </Col>
          </Fragment>
        )}

        {(employmentType === 'autonomo') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='autonomoStartActivityDate'
              label={<label>Desde quando exerce a atividade:</label>}
              help={errors.autonomoStartActivityDate && (errors.autonomoStartActivityDate)}
              validateStatus={errors.autonomoStartActivityDate && ('error')}
            >
              <Datepicker
                disabled={readOnlyInput}
                locale={locale}
              />
            </Form.Item>
          </Col>
        )}

        {employmentType && ['autonomo', 'liberal'].includes(employmentType) && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='hasServiceAgreement'
              className="form-user-checkbox"
              valuePropName="checked"
              label={<label></label>}
              help={errors.hasServiceAgreement && (errors.hasServiceAgreement)}
              validateStatus={errors.hasServiceAgreement && ('error')}
            >
              <Checkbox
                id="test-checkbox-hasServiceAgreement"
                disabled={readOnlyInput}
              >
                Possuo Contrato de Prestação de Serviços?
              </Checkbox>
            </Form.Item>
          </Col>
        )}

        {(employmentType === 'aposentado') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='privateSocialSecurity'
              className="form-user-checkbox"
              valuePropName="checked"
              label={<label></label>}
              help={errors.privateSocialSecurity && (errors.privateSocialSecurity)}
              validateStatus={errors.privateSocialSecurity && ('error')}
            >
              <Checkbox
                id="test-checkbox-privateSocialSecurity"
                disabled={readOnlyInput}
              >
                Aposentadoria privada?
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row>
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='irFree'
            className="form-user-checkbox"
            valuePropName="checked"
            label={<label></label>}
            help={errors.irFree && (errors.irFree)}
            validateStatus={errors.irFree && ('error')}
          >
            <Checkbox
              id="test-checkbox-irFree"
              disabled={readOnlyInput}
            >
              Isento de IR (Imposto de Renda)
            </Checkbox>
          </Form.Item>
        </Col>

        {(step.ref === 'titular') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='composeIncome'
              className="form-user-checkbox"
              valuePropName="checked"
              label={<label></label>}
              help={errors.composeIncome && (errors.composeIncome)}
              validateStatus={errors.composeIncome && ('error')}
            >
              <Checkbox
                id="test-checkbox-policy"
                disabled={readOnlyInput}
                onChange={(value) => setComposeIncome(value.target.checked)}
              >
                Fora o cônjuge, irá compor renda com mais pessoas
              </Checkbox>
            </Form.Item>
          </Col>
        )}

        {composeIncome && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='numberComposeIncome'
              label={<label>Selecione a quantidade:</label>}
              help={errors.numberComposeIncome && (errors.numberComposeIncome)}
              validateStatus={errors.numberComposeIncome && ('error')}
            >
              <InputNumber
                type='number'
                min={0}
                max={2}
                className="w-100"
                readOnly={readOnlyInput}
                placeholder='Digite a quantidade'
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {((step.situation === 'starting' || step.situation === 'remaking') && !readOnlyInput) ? <Form.List name="otherIncomes">
        {(fields, { add, remove }) => {
          return (
            <Fragment>
              {fields.map((field, index) => (
                <Fragment key={field.fieldKey}>
                  <Row>
                    <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name)
                            finalIncomeCalculate(String(income))
                          }}
                          style={{ position: 'absolute', color: 'red', left: 110, top: 0, fontSize: 22, zIndex: 999, cursor: 'pointer' }}
                        />

                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          label={<label>Outra Renda {index + 1}:</label>}
                          fieldKey={[field.fieldKey, 'name']}
                        >
                          <Input
                            name='name'
                            readOnly={readOnlyInput}
                            placeholder={'Digite o nome da fonte de rendimento'}
                          />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col className="px-1" lg={8} sm={12} xs={24}>
                      <Form.Item
                        {...field}
                        className="form-user-checkbox"
                        name={[field.name, 'rented']}
                        fieldKey={[field.fieldKey, 'rented']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          id="test-checkbox-property"
                          disabled={readOnlyInput}
                        >
                          Possui renda de aluguel:
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'value']}
                        fieldKey={[field.fieldKey, 'value']}
                        label={<label > Valor da Outra Renda {index + 1}:</label>}
                      >
                        <InputMoney
                          onChange={() => finalIncomeCalculate(String(income))}
                          id="test-form-input-income"
                          readOnly={readOnlyInput}
                          placeholder='Digite o valor dos rendimentos'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Fragment>
              ))}

              <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                <Form.Item>
                  <PlusButton
                    onClick={() => {
                      add()
                      finalIncomeCalculate(String(income))
                    }}
                    label="Adicionar Fonte de Renda"
                  />
                </Form.Item>
              </Col>
            </Fragment>
          )
        }}
      </Form.List> : null}

      {(step.form?.otherIncomes && readOnlyInput) && (
        <Fragment>
          {(step as any).form?.otherIncomes.map((field: IOtherIncome, index: number) => (
            <Fragment key={field.value}>
              <Row>
                <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                  <Form.Item
                    label={<label >Outra Renda {index + 1}:</label>}
                    key={index}
                  >
                    <Input
                      placeholder={'Digite o nome da fonte de rendimento'}
                      value={field.name}
                    />
                  </Form.Item>
                </Col>

                <Col className="px-1" lg={8} sm={12} xs={24}>
                  <Form.Item
                    className="form-user-checkbox"
                    valuePropName="checked"
                    label={<label></label>}
                  >
                    <Checkbox
                      id="test-checkbox-property"
                      disabled={readOnlyInput}
                      checked={field.rented}
                      value={field.rented}
                    >
                      Renda de aluguel?
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                  <Form.Item
                    fieldKey={[index, 'value']}
                    label={<label> Valor da Outra Renda {index + 1}:</label>}
                    key={index}
                  >
                    <InputMoney
                      id="test-form-input-otherIncome"
                      readOnly={readOnlyInput}
                      value={field.value}
                      placeholder='Insira o valor dos rendimentos'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Fragment>
          ))}
        </Fragment>
      )}

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item label={<label>Renda Total:</label>}>
          <CurrencyInput
            readOnly={true}
            value={finalIncome}
            placeholder='Total de rendas'
            prefix="R$ "
            className='ant-input'
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={2}
          />
        </Form.Item>
      </Col>
    </Form>
  )
}

export default FormIncome
