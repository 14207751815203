import dayjs from 'dayjs'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { _userLevel } from 'ecp/models/UsersModel'
import { IMergedDueAndDebitBalanceECPF } from 'ecpf/repositories/OperationsECPFRepository'
import { IProposalDetailsECPF, IAnticipationSimulationPayload, IGenerateTicketPayload } from 'ecpf/repositories/ProposalECPFRepository'
import { Holiday } from 'models/Holiday'
import { timeAsDayjs } from 'utils/time'

const ProposalDuesEcpfModel = {

  // Código enviado pela Rodobens
  // Aparentemete o código do produto no caso de simulação de antecipação de parcelas
  // não é igual ao código do produto da proposta
  // REF: https://agxsoftware.atlassian.net/browse/RODO-1825?focusedCommentId=14344
  productCode: '000002',

  // sempre '000' - Rodobens passou
  cobradoraCode: '000',

  sortSelectedDuesByNumeroSaldo: (selectedDues: IMergedDueAndDebitBalanceECPF[] = []): IMergedDueAndDebitBalanceECPF[] => {
    if (!selectedDues.length) return []
    return selectedDues.sort((a, b) => (a.numeroSaldo ?? 0) - (b.numeroSaldo ?? 0))
  },

  formatAnticipationSimulationPayload: async (proposal: IProposalDetailsECPF, selectedDues: IMergedDueAndDebitBalanceECPF[]): Promise<IAnticipationSimulationPayload> => {
    const parcelas = selectedDues.map(due => ({
      numeroParcela: due.numeroSaldo?.toString() || ''
    }))

    const despesas = {
      valorDespesaReembolsavel: 0,
      valorTarifaHonorario: 0
    }

    const operacoesAcordadas = [
      {
        codigoBackOffice: '01', // sempre 01
        numeroOperacao: proposal?.funcao?.numeroOperacao,
        parcelas
      }
    ]

    const holiday = new Holiday(dayjs().year())
    const allHolidays = await holiday.getHoliday()

    return {
      codigoProduto: ProposalDuesEcpfModel.productCode,
      valorProposto: ProposalDuesEcpfModel.calculateTotalParcelValue(selectedDues),
      dataBase: timeAsDayjs().startOf('day').format('YYYY-MM-DD'),
      quantidadeParcelas: selectedDues?.length || 0,
      dataPrimeiroVencimento: holiday.nextWorkingDay(dayjs(), allHolidays).startOf('day').format('YYYY-MM-DD'),
      despesas,
      operacoesAcordadas,
      codigoCobradora: ProposalDuesEcpfModel.cobradoraCode,
      fluxoParcelas: selectedDues.map(due => ({
        numeroParcela: due.numeroSaldo?.toString() || '',
        vencimento: due.dataVencimento,
        valorParcela: due.valorDaParcela || 0,
        valorDespesa: due.saldoDespesa,
        valorTarifa: due.saldoTarifa
      }))
    }
  },

  formaterGenerateTicketPayload: async (proposal: IProposalDetailsECPF, selectedDues: IMergedDueAndDebitBalanceECPF[]): Promise<IGenerateTicketPayload> => {
    const parcelas = selectedDues.map(due => ({
      numeroParcela: due.numeroSaldo?.toString() || ''
    }))

    const holiday = new Holiday(dayjs().year())
    const allHolidays = await holiday.getHoliday()

    return {
      numeroOperacao: proposal?.funcao?.numeroOperacao,
      codigoInstalacao: Number(selectedDues[0]?.codigoInstalacao),
      produtoAcordo: ProposalDuesEcpfModel.productCode,
      parcelas,
      dataVencimento: holiday.nextWorkingDay(dayjs(), allHolidays).startOf('day').format('YYYY-MM-DD'),
      quantidadeParcelas: 1,
      gerarBoleto: 'Sim',
      codigoCobradora: ProposalDuesEcpfModel.cobradoraCode
    }
  },

  calculateTotalParcelValue: (selectedDues: IMergedDueAndDebitBalanceECPF[]): number => {
    return selectedDues.reduce((total, due) => total + (due.saldoDevedor ?? 0), 0)
  },

  hasAcctionInDebitBalance: (userLevel: _userLevel): boolean => {
    return ['master', 'client'].includes(userLevel)
  },

  formatR$: (value?: number): string => {
    return value !== undefined ? R$(value) : '-'
  },

  downloadBase64File: (base64: string, fileName: string): void => {
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${base64}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  removeZeros: (value: string): string => {
    if (!value) return '-'
    return value.replace(/^0+/, '')
  }

}

export default ProposalDuesEcpfModel
